import React, { useEffect, useState } from "react";
import { HeaderContainer } from "./styles";

import Logo from "../../Assets/Images/logo.png";
import Auth from "../../services/auth";

function Header() {
  const [user, setUser] = useState(Auth.user());

  useEffect(() => {
    setUser(Auth.user());
  }, []);

  if (user?.cpf) {
    return (
      <HeaderContainer>
        <div>
          <img src={Logo} alt="Logo Doação de Sangue" />
          <span>Doação de Sangue</span>
        </div>
        <span>Bem vindo {user && user?.nome}</span>
        <div></div>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer>
        <div>
          <img src={Logo} alt="Logo Doação de Sangue" />
          <span>Doação de Sangue</span>
        </div>
        <span></span>
        <div></div>
      </HeaderContainer>
    );
  }
}

export default Header;
