import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);

  overflow-y: auto;
  position: fixed;
  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
`;

export const Content = styled.div`
  width: 70%;
  min-height: 254px;
  max-height: 260px;
  background: #fff;
  padding: 2rem 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%);
  max-height: 870px;

  @media (max-width: 833px) {
    top: 60px;
  }
  @media (max-width: 464px) {
    width: 87%;
  }
`;

export const Telefone = styled.div`
  background: #f3f3f3;

  h4 {
    font-weight: bolder;
    font-size: 20px;
    @media (max-width: 410px) {
      font-size: 16px;
    }
  }
`;

export const Endereço = styled.div`
  margin-top: 10px;
  h4 {
    font-size: 20px;
    font-weight: bolder;
    @media (max-width: 410px) {
      font-size: 16px;
    }
  }
`;

export const Tabela = styled.div`
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

export const Content2 = styled.div`
  width: 70%;
  min-height: 260px;
  background: #fff;
  padding: 2rem 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 420px;
  left: 50%;
  transform: translate(-50%);
  max-height: 870px;

  @media (max-width: 833px) {
    top: 360px;
  }

  @media (max-width: 627px) {
    top: 380px;
  }

  @media (max-width: 464px) {
    width: 87%;
  }

  @media (max-width: 450px) {
    margin-top: 15px;
  }

  button {
    border: none;
    border-radius: 50px;
    margin-left: 20px;

    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }
`;

export const Site = styled.div`
  margin-top: 10px;
  h4 {
    font-weight: bolder;
    font-size: 19px;
    @media (max-width: 410px) {
      font-size: 13px;
    }
  }
`;

export const Header = styled.div`
  display: flex;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    background: white;

    @media (max-width: 390px) {
      width: 35px;
      height: 35px;
    }
  }
`;
