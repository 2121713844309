import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { Tooltip } from "../../../Styles/Global";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  height: 30px;
  width: 334px !important;
  text-align: center;
  margin-top: 1rem;

  @media (max-width: 369px) {
    width: 260px !important;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--red-color);
  border-color: var(--red-color);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: all 0.3s linear;
  &:hover {
    filter: brightness(90%);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
  svg {
    vertical-align: middle;
    margin-left: 0.1rem;
  }
`;

export const Label = styled.label`
  display: block;
  white-space: nowrap;
  position: absolute;
  bottom: 50%;
  left: 1rem;
  color: var(--dark-color);
  font-size: var(--small-font-size);
  font-weight: 800;
  text-transform: uppercase;
  opacity: 0;
  transform: translate3d(0, 50%, 0) scale(1);
  transform-origin: 0 0;
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const ModalContent = styled.div`
  max-width: 100%;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Form = styled.form`
  width: 100%;
  text-align: center;
  h1 {
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  flex-wrap: wrap;
`;

export const IsCnpj = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 330px;
`;

export const RadioGroup = styled.div`
  height: 100%;
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
`;
export const Radio = styled.div`
  display: flex;
  width: 50px;
`;
export const Buttons = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5px;
`;

export const FormGroup = styled.div`
  flex: 1 1 20rem;
  margin: 0 0.5rem 1.5rem 0.5rem;
  position: relative;
  svg {
    position: absolute;
    top: 25%;
    right: 1%;
    cursor: pointer;
  }
  img {
    max-width: 3rem;
    height: auto;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 50%;
  width: 100%;
  ${Tooltip}
`;

export const WapperImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  @media screen and (max-width: 882px) {
    max-height: 150px;
  }
  @media screen and (max-width: 768px) {
    overflow-y: auto;
  }
`;

export const ImageGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 18px 7px #c5c5c5;
    -moz-box-shadow: 0px 0px 18px 7px #c5c5c5;
    box-shadow: 0px 0px 18px 7px #c5c5c5;
  }
  @media screen and (max-width: 1024px) {
    min-width: 120px;
  }
`;

export const Options = styled.div`
  width: 20%;
  bottom: 2%;
  display: flex;
  margin-left: 88%;
  margin-top: 5px;

  svg {
    font-size: 1.5rem;
    color: red;
    cursor: pointer;
  }
`;

export const BodyImage = styled.div`
  height: 100%;
  width: 100%;
`;

export const TitlePhoto = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 5px;
`;

export const Delete = styled.div`
  position: absolute;
  bottom: 0%;
  right: 0%;
  svg {
    font-size: 2rem;
    color: red;
    cursor: pointer;
  }
`;

export const FormButtons = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-around;
  margin-left: 33%;
`;

export const Text = styled.div`
  display: block;
  margin: 0;
  padding: 0.4rem 1.6rem;
  color: #212529;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 0.25rem;
  transition: box-shadow 300ms;
`;
