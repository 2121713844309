import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";

const Auth = {
  token: function () {
    try {
      const token = localStorage.getItem("token");
      jwt.verify(token, "doacaosangue@citec");
      var decoded = jwt_decode(token);
      return decoded.cpf;
    } catch (error) {
      return false;
    }
  },
  auth: function () {
    try {
      const token = localStorage.getItem("token");
      jwt.verify(token, "doacaosangue@citec");
      var decoded = jwt_decode(token);
      return decoded.auth;
    } catch (error) {
      return false;
    }
  },
  user: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      jwt.verify(token, "doacaosangue@citec");
      var decoded = jwt_decode(token);
      return decoded;
    } catch (error) {
      return false;
    }
  },
};

export default Auth;
