import styled from "styled-components";
import { IoArrowBackOutline } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";

export const IconBack = styled(IoArrowBackOutline)`
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 50px;
  cursor: pointer;
  transition: 0.25s;
  display: ${(props) => props.hidden && "none"};

  &:hover {
    color: var(--blue-color);
    transform: translateX(-5px);
  }
`;

export const IconDownload = styled(FiDownload)`
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 50px;
  cursor: pointer;
  transition: 0.25s;
  display: ${(props) => props.hidden && "none"};

  &:hover {
    color: var(--blue-color);
    transform: translateY(5px);
  }
`;
