import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  FormGroup,
  FormGroupHora,
  Button,
  Text,
  TextHora,
  DataTable,
  HoraTable,
  VagasTable,
  Input,
  Label,
  ExcluirTable,
  AddButton,
} from "./styles";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { IoTrashOutline } from "react-icons/io5";

export default function ModalEdit({ data, showModal, setShowModal }) {
  const modalRef = useRef();
  const [data_doacao, setData_doacao] = useState("");
  const [data_inicio, setData_inicio] = useState("");
  const [data_termino, setData_termino] = useState("");
  const [horario, setHorario] = useState([]);
  const [qtdeVagas, setQtdeVagas] = useState([]);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const formatarData = (data) => {
    if (!data || !data.includes("/")) return "";

    const partes = data.split("/");
    if (partes.length !== 3) return "";

    const [dia, mes, ano] = partes;
    if (dia.length !== 2 || mes.length !== 2 || ano.length !== 4) return "";

    return `${ano}-${mes}-${dia}`;
  };

  const formatarDataHora = (dataHora) => {
    if (!dataHora) return "";

    const [data, hora] = dataHora.split(" - ");
    if (!data || !hora) return "";

    const [dia, mes, ano] = data.split("/");
    if (dia.length !== 2 || mes.length !== 2 || ano.length !== 4) return "";

    return `${ano}-${mes}-${dia}T${hora}`;
  };

  useEffect(() => {
    if (!data) return;
    setData_doacao(formatarData(data?.data_doacao));
    setData_inicio(formatarDataHora(data?.data_inicio));
    setData_termino(formatarDataHora(data?.data_termino));

    if (data.horarios && data.horarios.length > 0) {
      setHorario(data.horarios.map((h) => h.horario_doacao));
      setQtdeVagas(data.horarios.map((h) => h.vagas));
    } else {
      setHorario([]);
      setQtdeVagas([]);
    }
  }, [data]);

  // useEffect(() => console.log(data.id), [data]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (horario.filter((h, i) => horario.indexOf(h) !== i).length > 0) {
      return Swal.fire({
        title: "Atenção!",
        text: "Horários repetidos!",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });
    }

    try {
      const updatedHorarios = horario.map((h, i) => ({
        horario_doacao: h,
        vagas: qtdeVagas[i],
      }));

      const calendarData = {
        data_doacao: data_doacao,
        data_inicio: data_inicio,
        data_termino: data_termino,
        horarios: updatedHorarios,
      };

      const response = await api.patch(`admin/calendars/${data.id}`, {
        data: calendarData,
      });

      if (response.data.error) {
        return Swal.fire({
          title: "Atenção",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--red-color)",
        });
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Agenda atualizada!",
        icon: "success",
        confirmButtonColor: "var(--red-color)",
      });

      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } catch (error) {
      Swal.fire({
        title: "Atenção",
        text: "Não foi possível executar!",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });
    }
  }

  function deleteHorario(index) {
    if (horario.length > 3) {
      setHorario((horario) => horario.filter((h, i) => i !== index));
      setQtdeVagas((vaga) => vaga.filter((v, i) => i !== index));
    } else {
      Swal.fire({
        title: "Atenção",
        text: "Mínimo de 3 horários!",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });
    }
  }

  function addHorario() {
    if (horario.length < 10) {
      setHorario([...horario, "00:00"]);
      setQtdeVagas([...qtdeVagas, "0"]);
    } else {
      Swal.fire({
        title: "Atenção",
        text: "Máximo de 10 horários!",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) =>
            modalRef.current === e.target ? setShowModal(!showModal) : null
          }
        >
          <animated.div style={animation}>
            <ModalContent>
              <Form onSubmit={handleSubmit}>
                <h1>Editar Agenda</h1>
                <FormContent>
                  <DataTable>
                    <Text style={{ paddingBottom: 20 }}>Datas e Horários</Text>
                    <FormGroup>
                      <Label>Data de doação</Label>
                      <Input
                        type="date"
                        placeholder="Insira a data de doação"
                        value={data_doacao}
                        onChange={(e) => setData_doacao(e.target.value)}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Inicio das inscrições</Label>
                      <Input
                        style={{ wordSpacing: "20px" }}
                        type="datetime-local"
                        placeholder="Insira a data e horário de início"
                        value={data_inicio}
                        onChange={(e) => setData_inicio(e.target.value)}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Término das inscrições</Label>
                      <Input
                        style={{ wordSpacing: "20px" }}
                        type="datetime-local"
                        placeholder="Insira a data e horário de término"
                        value={data_termino}
                        onChange={(e) => setData_termino(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </DataTable>
                  <HoraTable>
                    <TextHora>Horários</TextHora>
                    {horario.map((h, i) => (
                      <FormGroupHora key={i}>
                        <Input
                          type="time"
                          style={{
                            width: "100%",
                            borderRadius: 0,
                            borderRight: "1px solid black",
                          }}
                          value={h}
                          onChange={(e) => {
                            let hora = [...horario];
                            hora[i] = e.target.value;
                            setHorario(hora);
                          }}
                          required
                        />
                      </FormGroupHora>
                    ))}
                  </HoraTable>
                  <VagasTable>
                    <Text>Vagas</Text>
                    {qtdeVagas.map((c, i) => (
                      <ExcluirTable key={i}>
                        <Input
                          type="text"
                          min={0}
                          style={{ borderRadius: 0 }}
                          placeholder="Qtde de vagas"
                          value={c}
                          onChange={(e) => {
                            let qtde = [...qtdeVagas];
                            qtde[i] = e.target.value.replace(/\D/g, "");
                            setQtdeVagas(qtde);
                          }}
                          maxLength={3}
                          required
                        />
                        <IoTrashOutline
                          color={"var(--red-color)"}
                          size={25}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            deleteHorario(i);
                          }}
                        />
                      </ExcluirTable>
                    ))}
                  </VagasTable>
                  <AddButton
                    type="button"
                    onClick={() => {
                      addHorario();
                    }}
                  >
                    Adicionar novo horário
                  </AddButton>
                </FormContent>
                <Button type="submit">Atualizar agenda</Button>
              </Form>
              <CloseModalButton onClick={() => setShowModal(!showModal)} />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
