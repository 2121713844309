import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const Button = styled.button`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    border: 1px solid transparent;
    padding: .625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    text-transform: uppercase;
    will-change: transform;
    letter-spacing: .025em;
    font-size: .875rem;
    color: #fff;
    background-color: var(--red-color);
    border-color: var(--red-color);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all .3s linear;

    &:hover {
        filter: brightness(90%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }

    &:disabled {
        opacity: 50%;
        cursor: not-allowed;
    }

    svg {
        vertical-align: middle;
        margin-left: 0.1rem;
    }
`;

export const Label = styled.label`
    display: block;
    white-space: nowrap;
    position: absolute;
    bottom: 50%;
    left: 1rem;
    color: var(--dark-color);
    font-size: var(--small-font-size);
    font-weight: 800;
    text-transform: uppercase;
    opacity: 0;
    transform: translate3d(0, 50%, 0) scale(1);
    transform-origin: 0 0;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1), z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const ModalContent = styled.div`
    max-width: 100%;
    height: 90%;
    margin: 0 auto;
    background: #FFF;
    border-radius: 0.25rem;
    box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    position: relative;
`;

export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const Form = styled.form`
    width: 100%;
    text-align: center;

    h1 {
        margin-bottom: 2rem;
        text-transform: uppercase;
    }   
`;

export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Input = styled.input`
    display: flex;
    flex-direction: row;
    background-color: #f3f3f3;
    border: none;
    border-radius: 20px;
    height: 30px;
    width: 334px !important;
    text-align: center;

    @media(max-width:369px){
        width:260px !important;
    }
`;