import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { Tooltip } from "../../../../Styles/Global";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Input = styled.input`
  display: block;
  text-align: center;
  background: #e6e7e9;
  margin: 0;
  padding: 0.8rem 1.6rem;
  color: #212529;
  width: 100%;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  transition: box-shadow 300ms;
  &::placeholder {
    color: #999;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  }
  &:hover {
    box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  }
  &:placeholder-shown + label {
    visibility: hidden;
    z-index: -1;
  }
  &:not(:placeholder-shown) + label,
  &:focus:not(:placeholder-shown) + label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translate3d(0, -1.5rem, 0) scale(0.8);
    transition: transform 300ms, visibility 0ms, z-index 0ms;
  }
`;

export const AddButton = styled.button`
  grid-area: b;
  margin: 5% 0 5% 0;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--red-color);
  border-color: var(--red-color);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: all 0.3s linear;
  &:hover {
    filter: brightness(90%);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
  svg {
    vertical-align: middle;
    margin-left: 0.1rem;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--red-color);
  border-color: var(--red-color);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: all 0.3s linear;
  &:hover {
    filter: brightness(90%);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
  svg {
    vertical-align: middle;
    margin-left: 0.1rem;
  }
`;

export const Label = styled.div`
  white-space: nowrap;
  color: #212529;
  font-size: var(--small-font-size);
  font-weight: 800;
  text-transform: uppercase;
  text-align: left;
  padding-left: 5%;
`;

export const ModalContent = styled.div`
  max-width: 95%;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Form = styled.form`
  width: 100%;
  text-align: center;
  h1 {
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
`;

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.6fr 0.599fr;
  grid-template-rows: 1fr auto;
  flex-wrap: wrap;
  grid-template-areas:
    ". . ."
    "d h v"
    ". b b";
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "d d"
      "h v"
      "b b";
  }
`;

export const DataTable = styled.div`
  grid-area: d;
  display: grid;
`;

export const HoraTable = styled.div`
  grid-area: h;
  /* margin: 0 5% 0 5%; */
`;

export const DoadoresTable = styled.div`
  grid-area: p;
`;

export const VagasTable = styled.table`
  grid-area: v;
  /* margin: 0 5% 0 5%; */
`;

export const ExcluirTable = styled.table`
  display: flex;
  align-items: center;
`;

export const IsCnpj = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 330px;
`;

export const RadioGroup = styled.div`
  height: 100%;
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
`;
export const Radio = styled.div`
  display: flex;
  width: 50px;
`;
export const Buttons = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 0 0.5rem;
  height: fit-content;
`;

export const FormGroupHora = styled.div`
  display: flex;
  /* margin: 0.5rem 0.5rem 1.5rem 4.3rem; */
  /* position: relative; */
  align-items: center;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 50%;
  width: 100%;
  ${Tooltip}
`;

export const WapperImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  @media screen and (max-width: 882px) {
    max-height: 150px;
  }
  @media screen and (max-width: 768px) {
    overflow-y: auto;
  }
`;

export const ImageGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 18px 7px #c5c5c5;
    -moz-box-shadow: 0px 0px 18px 7px #c5c5c5;
    box-shadow: 0px 0px 18px 7px #c5c5c5;
  }
  @media screen and (max-width: 1024px) {
    min-width: 120px;
  }
`;

export const Options = styled.div`
  width: 20%;
  bottom: 2%;
  display: flex;
  margin-left: 88%;
  margin-top: 5px;

  svg {
    font-size: 1.5rem;
    color: red;
    cursor: pointer;
  }
`;

export const BodyImage = styled.div`
  height: 100%;
  width: 100%;
`;

export const TitlePhoto = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 5px;
`;

export const Delete = styled.div`
  position: absolute;
  bottom: 0%;
  right: 0%;
  svg {
    font-size: 2rem;
    color: red;
    cursor: pointer;
  }
`;

export const FormButtons = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-around;
  margin-left: 33%;
`;

export const Text = styled.div`
  display: block;
  margin: 0;
  padding: 0.4rem 1.6rem;
  color: #212529;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 0.25rem;
  transition: box-shadow 300ms;
`;

export const TextHora = styled.div`
  margin: 0;
  padding: 0.4rem 1.6rem;
  color: #212529;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 0.25rem;
  transition: box-shadow 300ms;
`;
