import React from "react";
import { TableContainer, TableBody, TableHead } from "./styles";

export default function TableCancelamentos({ data }) {
  return (
    <TableContainer>
      <TableHead>
        <tr>
          <td>Nome do Doador</td>
          <td>Cancelado por</td>
          <td>Data de Doação</td>
          <td>Horário Agendado Cancelado</td>
          <td>Data/Hora de Operação</td>
        </tr>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <tr key={i}>
            <td data-title="Nome do Doador">{d?.nome_doador}</td>
            <td
              data-title="Nome do Usuario"
              style={!d?.nome_usuario ? { color: "gray" } : null}
            >
              {d?.nome_usuario || "Ação do Doador"}
            </td>
            <td data-title="Data de Doação">
              {new Date(d.data_doacao).toLocaleDateString()}{" "}
            </td>
            <td data-title="Horário Agendado Cancelado">
              {d.horario_agendado_cancelado}
            </td>
            <td data-title="Data/Hora de Operação">
              {new Date(d.data_hora_operacao).toLocaleString()}
            </td>
          </tr>
        ))}
      </TableBody>
    </TableContainer>
  );
}
