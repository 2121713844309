import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0.5rem",
  borderWidth: 2,
  borderRadius: 4,
  borderColor: "#ddd",
  borderStyle: "dashed",
  backgroundColor: "#f4f5f7",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  fontSize: "20px",
  textAlign: "center",
};
const activeStyle = { borderColor: "#2196f3" };
const acceptStyle = { borderColor: "#00e676" };
const rejectStyle = { borderColor: "#ff1744" };

export default function UploadImage({ setFile }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0 || acceptedFiles.length > 1)
        return alert("Selecione uma imagem!");
      setFile(acceptedFiles);
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <small style={{ position: "inherit" }}>
        Arraste a planilha ou clique aqui
      </small>
    </div>
  );
}
