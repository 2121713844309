import { useEffect, useMemo, useState } from "react";
import api from "../../services/api";
import BarChart from "../../Components/Charts/BarChart";
import PieChart from "../../Components/Charts/PieChart";
import {
  Grafico,
  BotoesContainer,
  Botoes,
  GraficoGerado,
  Titulos,
} from "./Styles";

function Graficos() {
  const [pizza, setpizza] = useState("");
  const [cidade, setcidade] = useState("");
  const [bairro, setbairro] = useState("");
  const [idade, setidade] = useState("");

  const [pizzaGrafico, setPizzaGrafico] = useState("");
  const [cidadeGrafico, setCidadeGrafico] = useState("");
  const [bairroGrafico, setBairroGrafico] = useState("");
  const [idadeGrafico, setIdadeGrafico] = useState("");

  const cores = useMemo(() => [
    "#e66c37",
    "#12239e",
    "#118dff",
    "#1aab40",
    "#197278",
    "#d64550",
    "#d9b300",
    "#744ec2",
    "#e044a7",
    "#6b007b",
    "#8E3E2D",
  ], []);

  const [pizzaButton, setPizzaButton] = useState(true);
  const [idadeButton, setIdadeButton] = useState(false);
  const [cidadeButton, setCidadeButton] = useState(false);
  const [bairroButton, setBairroButton] = useState(false);

  useEffect(() => {
    async function dados() {
      await api.get("/admin/pizza").then((response) => {
        setpizza(response.data.pizzaArray);
      });
      await api.get("/admin/dadosgrafico?tipo=idade").then((response) => {
        setidade(response.data.datasArray);
      });
      await api.get("/admin/dadosgrafico?tipo=cidade").then((response) => {
        setcidade(response.data.datasArray);
      });
      await api.get("/admin/dadosgrafico?tipo=bairro").then((response) => {
        setbairro(response.data.datasArray);
      });
    }
    dados();
  }, []);

  useEffect(() => {
    setPizzaGrafico({
      labels: pizza.labels,
      datasets: [
        {
          label: "Doadores",
          data: pizza.data,
          backgroundColor: cores,
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "right",
          },
          title: {
            display: true,
            text: "Total de Doações por mês",
          },
        },
      },
    });
  }, [pizza, cores]);

  useEffect(() => {
    setBairroGrafico({
      labels: bairro.labels,
      datasets: [
        {
          label: "Doadores",
          data: bairro.data,
          backgroundColor: cores,
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Total de Doações por Bairro",
          },
        },
      },
    });
  }, [bairro, cores]);

  useEffect(() => {
    setIdadeGrafico({
      labels: idade.labels,
      datasets: [
        {
          label: "Doadores",
          data: idade.data,
          backgroundColor: cores,
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Total de Doações por Idade",
          },
        },
      },
    });
  }, [idade, cores]);

  useEffect(() => {
    setCidadeGrafico({
      labels: cidade.labels,
      datasets: [
        {
          label: "Doadores",
          data: cidade.data,
          backgroundColor: cores,
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Total de Doações por Cidade",
          },
        },
      },
    });
  }, [cidade, cores]);

  return (
    <div>
      <BotoesContainer>
        <Botoes
          onClick={() => {
            setPizzaButton(true);
            setCidadeButton(false);
            setIdadeButton(false);
            setBairroButton(false);
          }}
        >
          Total
        </Botoes>
        <Botoes
          onClick={() => {
            setPizzaButton(false);
            setCidadeButton(true);
            setIdadeButton(false);
            setBairroButton(false);
          }}
        >
          Cidade
        </Botoes>
        <Botoes
          onClick={() => {
            setPizzaButton(false);
            setCidadeButton(false);
            setIdadeButton(false);
            setBairroButton(true);
          }}
        >
          Bairro
        </Botoes>
        <Botoes
          onClick={() => {
            setPizzaButton(false);
            setCidadeButton(false);
            setIdadeButton(true);
            setBairroButton(false);
          }}
        >
          Idade
        </Botoes>
      </BotoesContainer>
      <GraficoGerado>
        <Grafico show={pizzaButton} size="40vw">
          <Titulos show={pizzaButton}>
            <h2>Total de Doações do último ano por data</h2>
          </Titulos>
          {!!pizzaGrafico && <PieChart chartData={pizzaGrafico} />}
        </Grafico>
        <Grafico show={cidadeButton} size="80vw">
          <Titulos show={cidadeButton}>
            <h2>Total de Doações do último ano por cidade</h2>
          </Titulos>
          {!!cidadeGrafico && <BarChart chartData={cidadeGrafico} />}
        </Grafico>
        <Grafico show={bairroButton} size="80vw">
          <Titulos show={bairroButton}>
            <h2>Total de Doações do último ano por bairro</h2>
          </Titulos>
          {!!bairroGrafico && <BarChart chartData={bairroGrafico} />}
        </Grafico>
        <Grafico show={idadeButton} size="80vw">
          <Titulos show={idadeButton}>
            <h2>Total de Doações do último ano por idade</h2>
          </Titulos>
          {!!idadeGrafico && <BarChart chartData={idadeGrafico} />}
        </Grafico>
      </GraficoGerado>
    </div>
  );
}

export default Graficos;
