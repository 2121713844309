import styled from "styled-components";

export const Grafico = styled.div`
// display: ${props => !props.show && "none"};
width: ${props => props.show ? props.size:"0"};
// width: 40vw;
`
export const Titulos = styled.div`
display: ${props => props.show ? "flex": "none"};
justify-content: center;
`

export const BotoesContainer = styled.div`
display: flex;
aling-itens: center;
justify-content: center;
width: 100vw;
gap: 10px;
`

export const Botoes = styled.button`
background-color: #C22A40;
padding: 0.5rem;
border-radius: 0.5rem;
margin-top: 0.5rem;
color: white;
font-size: 1.2rem;
`

export const GraficoGerado = styled.div`
display: flex;
aling-itens: center;
justify-content: center;
margin-top: 2vh;
`