import React, { useState, useEffect } from "react";
import {
  Background,
  Container,
  ContentForm,
  Titulo,
  FormController,
  Button,
  Label,
  Input,
  Hr,
  Form,
  SelectEstados,
  DivSelect,
  DivEsquerda,
  DivDireita,
  DivCentral,
  // Select,
} from "./style";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import api from "../../services/api";
import "./inputMask.css";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import CPF from "cpf-check";
import CalculaIdade from "../../services/CalculaData";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const Register = () => {
  const history = useHistory();

  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [cep, setCep] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  // const [sexo, setSexo] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [data_nasc, setData_nasc] = useState("");
  const [visible, setVisible] = useState(false);

  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");

  const [receberEmail, setReceberEmail] = useState(true);

  function abrirPDF() {
    window.open(
      "https://doacaosangue.fiecdev.com.br/Politica.pdf",
      "PDF",
      "height=780px  width=560px"
    );
  }

  useEffect(() => {
    if (!cep.includes("_") && cep.length > 0) {
      fetch(`https://viacep.com.br/ws/${cep.replace("-", "")}/json/`)
        .then((res) => res.json())
        .then((data) => {
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setComplemento(data.complemento);
          setCidade(data.localidade);
          setEstado(data.uf);
        });
    }
  }, [cep]);

  const d = new Date();
  let ano = d.getFullYear();
  ano -= 16;

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      CalculaIdade(
        data_nasc.split("/")[2] +
          "-" +
          data_nasc.split("/")[1] +
          "-" +
          data_nasc.split("/")[0]
      ) < 16
    ) {
      return Swal.fire({
        title: "Data inválida!",
        text: "Para se cadastrar é necessario ter pelo menos 16 anos!",
        icon: "warning",
      });
    }
    if (data_nasc.split("/")[2] < ano - 84) {
      return Swal.fire({
        title: "Data inválida!",
        text: "Verifique a data e tente novamente!",
        icon: "warning",
      });
    }

    if (CPF.validate(cpf) === false) {
      return Swal.fire({
        title: "CPF inválido!",
        text: "O cpf não foi reconhecido",
        icon: "warning",
      });
    }

    if (senha !== confirmSenha) {
      return swal("Atenção!", "As senhas não estão iguais", "warning");
    }

    const data = {
      cpf: cpf,
      nome: nome,
      data_nasc: data_nasc,
      cep: cep,
      email: email,
      telefone: telefone,
      senha: senha,
      // sexo: sexo,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      uf: estado,
      numero: numero,
      logradouro: logradouro,
      receber_emails: receberEmail,
    };

    try {
      const response = await api.post("/register", { data });
      if (response.data.error) {
        return Swal.fire({
          title: "Alerta!",
          text: response.data.error,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }

      Swal.fire({
        title: "Sucesso!",
        text: response.data.success,
        icon: "success",
        confirmButtonText: "OK",
      });

      localStorage.setItem("token", response.data.token);
      setTimeout(() => {
        history.push({
          pathname: "/",
        });
        window.location.reload();
      }, 2000);
    } catch (error) {
      Swal.fire({
        title: "Alerta!",
        text: "Serviço indisponível no momento",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  useEffect(() => {
    console.log(
      data_nasc.split("/")[2] +
        "-" +
        data_nasc.split("/")[1] +
        "-" +
        data_nasc.split("/")[0]
    );
  }, [data_nasc]);

  return (
    <Background>
      <Container>
        {/* <Link to={"/Login"}>
          <img src={voltar} alt="Erro" />
        </Link> */}
        <ContentForm>
          <Titulo>
            <legend>Meu Cadastro</legend>
          </Titulo>
          <Form onSubmit={handleSubmit}>
            <DivCentral>
              <DivEsquerda>
                &nbsp;Informações Pessoais
                <Hr></Hr>
                <FormController>
                  <Label>CPF: </Label>
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    value={cpf}
                    pattern="([0-9]{3}\s?\.?\s?[0-9]{3}\s?\.?\s?[0-9]{3}\s?-?\s?[0-9]{2})"
                    onChange={(e) => setCpf(e.target.value)}
                    style={{ display: "flex", backgroundColor: "#d0d0d0" }}
                    required
                    className="InputMas"
                  />
                </FormController>
                <FormController>
                  <Label>Nome completo: </Label>
                  <Input
                    type="text"
                    name="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    pattern="([A-ZÀ-Ú]* [A-ZÀ-Ú ]*$)"
                    title="Informe pelo menos um sobrenome"
                    // normalize={value => (value || '').toUpperCase()}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Data de nascimento: </Label>
                  <InputMask
                    type="text"
                    mask="99/99/9999"
                    value={data_nasc}
                    onChange={(e) => setData_nasc(e.target.value)}
                    pattern="(?:((?:0[1-9]|1[0-9]|2[0-9])\/(?:0[1-9]|1[0-2])|(?:30)\/(?!02)(?:0[1-9]|1[0-2])|31\/(?:0[13578]|1[02]))\/(?:19|20)[0-9]{2})"
                    title="Insira uma data válida"
                    required
                    min="1953-01-01"
                    max="9999-12-31"
                    className="InputMas"
                    style={{ backgroundColor: "#d0d0d0" }}
                  />
                </FormController>
                {/* <FormController>
                  <Label title="Sexo Biológico (para fins de compatibilidade de doação)">
                    Sexo Biológico:
                  </Label>
                  <Select
                    required
                    value={sexo}
                    onChange={(e) => setSexo(e.target.value)}
                    title="Sexo Biológico (para fins de compatibilidade de doação)"
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </Select>
                </FormController> */}
                <FormController>
                  <Label>Email: </Label>
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Celular: </Label>
                  <InputMask
                    type="text"
                    name="email"
                    mask="(99) 99999-9999"
                    pattern="([^0-9]{0,}([0-9]{2})[^0-9]{0,}([0-9]{5})[^0-9]{0,}([0-9]{4}).*)"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    style={{ display: "flex", backgroundColor: "#d0d0d0" }}
                    required
                    className="InputMas"
                  />
                </FormController>
                <FormController>
                  <Label>Senha: </Label>
                  <Input
                    type={visible ? "text" : "password"}
                    name="senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Confirmar Senha: </Label>
                  <Input
                    type={visible ? "text" : "password"}
                    name="senha"
                    value={confirmSenha}
                    onChange={(e) => setConfirmSenha(e.target.value)}
                    required
                  />
                  {visible ? (
                    <IoEyeOutline
                      size={22}
                      color="#000"
                      onClick={() => setVisible(!visible)}
                    />
                  ) : (
                    <IoEyeOffOutline
                      size={22}
                      color="#000"
                      onClick={() => setVisible(!visible)}
                    />
                  )}
                </FormController>
                <div></div>
              </DivEsquerda>

              <DivDireita>
                &nbsp;Endereço
                <Hr></Hr>
                <FormController>
                  <Label>CEP: </Label>
                  <InputMask
                    type="text"
                    mask="99999-999"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    style={{ display: "flex", backgroundColor: "#d0d0d0" }}
                    required
                    className="InputMas"
                    pattern="\d{5}-?\d{3}"
                  />
                </FormController>
                <FormController>
                  <Label>Logradouro (Rua/Avenida): </Label>
                  <Input
                    style={{ fontSize: "13.2px" }}
                    type="text"
                    name="rua"
                    value={logradouro}
                    onChange={(e) => setLogradouro(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Número: </Label>
                  <Input
                    type="text"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                    style={{ display: "flex", backgroundColor: "#d0d0d0" }}
                    required
                    // className="InputMas"
                  />
                </FormController>
                <FormController>
                  <Label>Bairro: </Label>
                  <Input
                    style={{ fontSize: "13.2px" }}
                    type="text"
                    name="Bairro"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    required
                  />
                </FormController>
                <FormController>
                  <Label>Complemento: </Label>
                  <Input
                    type="text"
                    name="complemento"
                    placeholder="(Opcional) "
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                  />
                </FormController>
                <FormController>
                  <Label>Cidade: </Label>
                  <Input
                    type="text"
                    name="Cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    required
                  />
                </FormController>
                <DivSelect>
                  Estado:
                  <SelectEstados
                    name="estados-brasil"
                    required
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                  >
                    <option selected value={estado}>
                      {estado}
                    </option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </SelectEstados>
                </DivSelect>
                {/* <FormController>
                                <Label>Logradouro: </Label>
                                <Input
                                    type="text"
                                    value={logradouro}
                                    onChange={e => setLogradouro(e.target.value)}
                                    style={{ display: "flex", backgroundColor: "#d0d0d0" }}
                                    required
                                    // className="InputMas"
                                />
                            </FormController> */}
                <div></div>
              </DivDireita>
              <Button>
                <FormController className="termos">
                  <input
                    name="permissao"
                    htmlFor="permissao"
                    type="checkbox"
                    className="checkbox"
                    checked={receberEmail}
                    onChange={(e) => setReceberEmail(e.target.checked)}
                    required
                  />
                  <label
                    id="permissao"
                    className="label"
                    style={{ fontSize: "12px" }}
                  >
                    Deseja receber lembretes por e-mail sobre as próximas datas
                    de doação
                  </label>
                </FormController>
                <FormController className="termos">
                  <input
                    className="checkbox"
                    type="checkbox"
                    name="confirmar"
                    // value={terms}
                    // onChange={e => setTerms(e.target.value)}
                    required
                  />
                  <label
                    className="label"
                    style={{ fontSize: "12px" }}
                    onClick={abrirPDF}
                  >
                    Aceito as Políticas de privacidade
                  </label>
                </FormController>
                <button type="submit" name="submit">
                  Cadastrar
                </button>
                <br />
                <br />
                <br />
              </Button>
            </DivCentral>
          </Form>
        </ContentForm>
      </Container>
    </Background>
  );
};
export default Register;
