import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Background = styled.div`
  width: 100%;
  height: 94.2%;
  overflow-y: auto;
  position: fixed;
  background-color: #fff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
  margin-bottom: 2em;
  padding-bottom: 200px;
`;

export const Container = styled.div`
  margin-bottom: 20px;
  border: 2px solid green;
`;

export const Titulo = styled.h1`
  text-align: center;
  font-weight: 700;
`;

export const ContentForm = styled.div`
  width: 40%;
  background: #fff;
  min-height: 785px;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%);
  float: right;

  @media (max-width: 1150px) {
    width: 60%;
  }

  @media (max-width: 659px) {
    width: 80%;
  }

  @media (max-width: 470px) {
    width: 91%;
  }

  @media (max-width: 394px) {
    width: 99%;
  }
`;

export const Content = styled.div`
  width: 95%;
  /* overflow: scroll; */
  background: #fff;
  padding: 2rem 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%);

  h1 {
    @media screen and (max-width: 480px) {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 464px) {
    width: 87%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 150px 150px;
  align-items: center;
  grid-template-areas: "h c p";

  h1 {
    grid-area: h;
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-template-areas:
      "h h"
      "c p ";
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;

export const FormController = styled.div`
  margin-top: 0.8rem;
  background-color: #ffffff;
  // &:last-child{

  //     margin-top: .5rem;
  //     align-items: center;
`;

export const Table = styled.table`
  border: 1px solid #000;
`;

export const THead = styled.th`
  text-align: center;
  border: 1px solid #000;
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: red;

  img {
    width: 45px;
    height: 80px;
    margin-top: 1%;
    margin-left: 50px;

    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }

    @media (max-width: 810px) {
      margin-left: 25px;
      width: 40px;
    }

    @media (max-width: 656px) {
      display: none;
      /* margin-left: 22px;
            width:30px;
            height: 40px;
            margin-top: 25px; */
    }
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  height: 30px;
  width: 334px !important;
  text-align: center;

  @media (max-width: 369px) {
    width: 260px !important;
    font-size: 12px;
  }
`;

export const Label = styled.label`
  margin-left: 1rem;
`;

export const Button = styled.button`
  font-weight: bolder;
  background: var(--red-color);
  border: 0;
  height: fit-content;
  width: 140px;
  border-radius: 0.25rem;
  padding: 0.6rem 0.5rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    opacity: 90%;
    transform: scale(1.1);
  }

  &:nth-child(2) {
    grid-area: c;
  }

  &:nth-child(3) {
    grid-area: p;
  }

  @media screen and (max-width: 480px) {
    left: 32px;
    top: 80px;
  }
`;

export const FormButton = styled.button`
  position: absolute;
  right: 200px;
  top: 30px;

  button {
    font-weight: bolder;
    background: var(--red-color);
    border: 0;
    border-radius: 0.25rem;
    padding: 0.6rem 0.5rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 650px) {
    left: 32px;
    top: 80px;
  }

  @media screen and (max-width: 464px) {
    top: 110px;
  }
`;

export const Filters = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (max-width: 760px) {
    justify-content: center;
  }
`;

export const Select = styled.select`
  border: 2px solid var(--red-color);
  border-radius: 2rem;
  height: 42px;
  padding: 0 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1em, right 10px top 1em;
  background-size: 5px 5px, 5px 5px;
  background-color: #f3f3f3;

  @media screen and (max-width: 610px) {
    border: none;
  }
`;
