import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 50px;

  padding: 5px;

  display: flex;
  align-items: center;

  background-color: var(--red-color);

  > div:first-child {
    flex: 1;

    width: fit-content;

    display: flex;
    align-items: center;
    justify-content: start;

    gap: 0.625rem;

    @media (max-width: 920px) {
      > span {
        display: none;
      }
    }
  }

  > div:last-child {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: end;

    gap: 0.625rem;
  }

  span {
    color: white;
    font-size: 1.25rem;
    text-align: center;
  }

  span:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 40px;
    padding: 2.5px;
    border-radius: 8px;

    &:hover {
      background-color: #991b1b;
    }
  }
`;
