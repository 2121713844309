import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import InputMask from "react-input-mask";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
} from "./styles";
import api from "../../../services/api";
import swal from "sweetalert";
import "./styles.css";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [email, setEmail] = useState("");
  const [dataNasc, setDataNasc] = useState("");
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (cpf.indexOf("_") === -1 && cpf !== "") {
      try {
        const response = await api.get(`/admin/users/?cpf=${cpf}`);
        if (response.data.error) {
          return swal("Atenção!", response.data.error, "warning");
        }
        if (response.data.doadores.length) {
          setNome(response.data.doadores[0].nome);
          setDataNasc(response.data.doadores[0].data_nasc);
          setEmail(response.data.doadores[0].email);
          setSenha(Math.floor(Math.random() * 10000).toString());
        } else {
          return swal("Atenção!", "CPF inexistente!", "warning");
        }
      } catch (error) {
        alert(error);
      }
    } else {
      setNome("");
      setEmail("");
      setDataNasc("");
      setSenha("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = { cpf: cpf, senha: senha };
    try {
      const response = await api.patch(`/admin/changepassword`, {
        data,
      });
      if (response.data.error) {
        return swal("Atenção!", response.data.error, "warning");
      }
      swal("Sucesso!", response.data.success, "success");
      setTimeout(() => {
        setShowModal(!showModal);
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) =>
            modalRef.current === e.target ? setShowModal(!showModal) : null
          }
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Restauração de Senha</h3>
                <FormContent>
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    value={cpf}
                    className="maskCPFRecuperacao"
                    onChange={(e) => setCpf(e.target.value)}
                    style={{ textAlign: "center" }}
                    placeholder="Insira o CPF"
                    required
                  />
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value)}
                    disabled
                  />
                  <Input
                    type="email"
                    value={email}
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                  <Input
                    type="text"
                    value={dataNasc}
                    placeholder="Data de Nascimento"
                    onChange={(e) => setDataNasc(e.target.value)}
                    disabled
                  />
                  <Input
                    type="text"
                    value={senha}
                    placeholder="Nova senha"
                    onChange={(e) => setSenha(e.target.value)}
                    disabled
                  />
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  setCpf("___.___.___-__");
                  setNome(null);
                  setEmail(null);
                  setDataNasc(null);
                  setSenha(null);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
