import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Background = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  overflow-y: auto;

  background-color: #fff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
`;

export const Titulo = styled.h1`
  text-align: center;
  font-weight: 700;
`;

export const ContentForm = styled.div`
  width: 40%;
  min-height: 785px;

  padding: 2rem 2rem;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);

  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%);

  float: right;

  @media (max-width: 1150px) {
    width: 60%;
  }

  @media (max-width: 659px) {
    width: 80%;
  }

  @media (max-width: 470px) {
    width: 91%;
  }

  @media (max-width: 394px) {
    width: 99%;
  }
`;

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  max-height: 85%;
  height: fit-content;

  overflow-y: auto;

  padding: 2rem;
  margin: 40px auto;

  background: #fff;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;

  > div:nth-child(1) {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
  }

  h1 {
    @media screen and (max-width: 650px) {
      margin-bottom: 70px;
    }
  }

  @media (max-width: 464px) {
    width: 100%;
  }
`;

export const CheckBox = styled.input`
  display: flex;
  align-items: center;
`;

export const FormController = styled.div`
  margin-top: 0.8rem;
  background-color: #ffffff;
  // &:last-child{
  //     margin-top: .5rem;
  //     align-items: center;
`;

export const Table = styled.table`
  border: 1px solid #000;
`;

export const THead = styled.th`
  text-align: center;
  border: 1px solid #000;
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: red;

  img {
    width: 45px;
    height: 80px;
    margin-top: 1%;
    margin-left: 50px;

    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }

    @media (max-width: 810px) {
      margin-left: 25px;
      width: 40px;
    }

    @media (max-width: 656px) {
      display: none;
      /* margin-left: 22px;
        width:30px;
        height: 40px;
        margin-top: 25px; */
    }
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  height: 30px;
  width: 334px !important;
  text-align: center;

  @media (max-width: 369px) {
    width: 260px !important;
    font-size: 12px;
  }
`;

export const Label = styled.label`
  margin-left: 1rem;
`;

export const Button = styled.button`
  padding: 0.6rem 0.5rem;

  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;

  background: var(--red-color);

  transition: all 0.3s linear;

  border: 0;
  border-radius: 0.25rem;

  cursor: pointer;

  &:hover {
    opacity: 90%;
    transform: scale(1.1);
  }
`;

export const FormButton = styled.button`
  position: absolute;
  right: 200px;
  top: 30px;

  button {
    font-weight: bolder;
    background: var(--red-color);
    border: 0;
    border-radius: 0.25rem;
    padding: 0.6rem 0.5rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 650px) {
    left: 32px;
    top: 80px;
  }

  @media screen and (max-width: 464px) {
    top: 110px;
  }
`;

export const FiltroContainer = styled.div`
  height: fit-content;
  width: fit-content;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: start;

  gap: 0.625rem;
  padding: 5px 10px;
  margin-bottom: 0.625rem;

  border-width: 2px;
  border-style: solid;
  border-radius: 1.5625rem;
  border-color: var(--red-color);

  label {
    font-size: 1.25rem;
    font-weight: bold;
  }

  select {
    height: 2.5rem;

    text-align: center;

    border-radius: 1.25rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 1.25rem;
    border-color: var(--red-color);
  }
`;
