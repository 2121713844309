import { IconBack, IconDownload } from "./style";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function GoBackPdf() {
  const [Hidden, setHidden] = useState(false);
  const history = useHistory();

  async function Print() {
    setHidden(true);
    setTimeout(() => {
      window.print();
    }, 500);
    setTimeout(() => {
      setHidden(false);
    }, 500);
  }

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if ((e.metaKey || e.ctrlKey) && e.code === "KeyP") {
        setHidden(true);
        setTimeout(() => {
          setHidden(false);
        }, 1500);
      }
    });
  });

  return (
    <>
      <IconBack
        title="Voltar"
        onClick={() => history.goBack()}
        hidden={Hidden}
      />
      <IconDownload title="Voltar" onClick={() => Print()} hidden={Hidden} />
    </>
  );
}
