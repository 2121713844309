import { Link, Cont } from "./style";
import novaEntrada from "../../Assets/Images/nova_entrada.jpeg";
import Modal from "react-modal";

Modal.setAppElement("#root");
const HomeTemporaria = () => {
  return (
    <>
      <Link to="/Home"> Clique para continuar </Link>
      <Cont>
        <img src={novaEntrada} alt="erro" />
      </Cont>
    </>
  );
};

export default HomeTemporaria;
