import React from "react";
import { TableContainer, TableBody, TableHead, DataIsNull } from "./styles";

export default function TableGrupoDoadores({ data }) {
  return (
    <TableContainer>
      <TableHead>
        <tr>
          <td>Nome do Doador</td>
          <td>CPF do Doador</td>
          <td>Data e Hora de Doação</td>
        </tr>
      </TableHead>
      <TableBody>
        {data.length > 0 ? (
          data.map((usuario, indexU) => {
            return (
              <React.Fragment key={`user-${indexU}`}>
                <tr>
                  <td colSpan="3" className="usuario">
                    {`${usuario?.nome_usuario} - ${usuario?.cpf_usuario}`}
                  </td>
                </tr>
                {usuario.data_doacoes.map((doacao, indexD) =>
                  doacao.doadores.map((doador, indexDD) => (
                    <tr key={`${indexU}-${indexD}-${indexDD}`}>
                      <td>{doador?.nome_doador}</td>
                      <td>{doador?.cpf_doador}</td>
                      <td>{`${
                        new Date(doacao.data_doacao)
                          .toLocaleString()
                          .split(",")[0]
                      } - ${doador.horario_doacao}`}</td>
                    </tr>
                  ))
                )}
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <DataIsNull colSpan="3">Selecione uma Data de Doação</DataIsNull>
          </tr>
        )}
      </TableBody>
    </TableContainer>
  );
}
