import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  FormGroup,
  Button,
  Text,
  DataTable,
  HoraTable,
  DoadoresTable,
  VagasTable,
} from "./styles";
import api from "../../../../services/api";
// import Auth from "../../../../services/auth";
import { useHistory } from "react-router-dom"; 

export default function ModalRegister({ showModal, setShowModal, id }) {
  const modalRef = useRef();
  const [data_doacao, setData_doacao] = useState("");
  const [horarios, setHorarios] = useState([]);
  const history = useHistory();

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    api
      .get(`/calendars/${id}`)
      .then((response) => {
        if (response.data.calendarios[0].data_doacao) {
          const fulldate = response.data.calendarios[0].data_doacao.split("T");
          const formattedDate = fulldate[0];
          setData_doacao(formattedDate);
        }
        setHorarios(response.data.calendarios[0].horarios);
      })
      .catch((error) => alert(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    
    history.push({ pathname: "/PdfDoadores",
                  state: { id: id }              
  })
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) =>
            modalRef.current === e.target ? setShowModal(!showModal) : null
          }
        >
          <animated.div style={animation}>
            <ModalContent>
              <Form onSubmit={handleSubmit}>
                <h1>Agenda</h1>
                <FormContent>
                  <DataTable>
                    <FormGroup>
                      <Text>{data_doacao}</Text>
                    </FormGroup>
                  </DataTable>
                  <HoraTable>
                    <Text>Horários</Text>
                    {horarios.map((h, i) => (
                      <FormGroup key={i}>{h.horario_doacao}</FormGroup>
                    ))}
                  </HoraTable>
                  <VagasTable>
                    <Text>Vagas</Text>
                    {horarios.map((h, i) => (
                      <FormGroup key={i}>
                        {h.vagas - h.vagas_preenchidas}
                      </FormGroup>
                    ))}
                  </VagasTable>
                  <DoadoresTable>
                    <Text>Doadores</Text>
                    {horarios.map((h, i) => (
                      <FormGroup key={i}>{h.vagas_preenchidas}</FormGroup>
                    ))}
                  </DoadoresTable>
                </FormContent>
                <Button type="submit">Gerar PDF</Button>
              </Form>
              <CloseModalButton onClick={() => setShowModal(!showModal)} />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
