import React, { useState, useEffect } from "react";
import { Background, Content, Button, Form, FiltroContainer } from "./style";

import api, { baseURL } from "../../services/api";
import Auth from "../../services/auth";

import Pagination from "../../Components/Pagination";
import TableCancelamentos from "../../Components/Tables/TableCancelamentos";

import Swal from "sweetalert2";
import { format } from "date-fns";

const Cancelamentos = () => {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [cpf, setCPF] = useState("");
  const [cpfs, setCPFs] = useState([]);

  const [dataDoacao, setDataDoacao] = useState("");
  const [datasDoacoes, setDatasDoacoes] = useState([]);

  const [auth] = useState(Auth.auth());

  async function fetchData(cpf, dataDoacao) {
    let query = "";
    if (cpf || dataDoacao) {
      query = "?";
      if (cpf) {
        query += `cpf_doador=${cpf}&`;
      }
      if (dataDoacao) {
        query += `data_doacao=${dataDoacao}`;
      }
    }
    try {
      const response = await api.get(`/cancellations${query}`);
      const responseData = response.data;

      setData(responseData);
      setPage(1);

      const datasUnicas = new Set();
      const cpfsUnicos = new Set();

      if (datasDoacoes.length === 0 || cpfs.length === 0) {
        const formatandoDatas = [];
        const formatandoCPFs = [];

        responseData.forEach((item) => {
          if (!cpfsUnicos.has(item.cpf_doador)) {
            cpfsUnicos.add(item.cpf_doador);
            formatandoCPFs.push({
              label: item.cpf_doador,
              value: item.cpf_doador,
            });
          }
          if (!datasUnicas.has(item.data_doacao)) {
            datasUnicas.add(item.data_doacao);
            formatandoDatas.push({
              label: format(new Date(item.data_doacao), "dd/MM/yyyy"),
              value: item.data_doacao.split("T")[0],
            });
          }
        });

        if (cpfs.length === 0) {
          setCPFs(formatandoCPFs);
        }

        if (datasDoacoes.length === 0) {
          setDatasDoacoes(formatandoDatas);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData(cpf, dataDoacao);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf, dataDoacao]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / 10));
  }, [data]);

  async function handleCancelationsDownload(e) {
    e.preventDefault();

    Swal.fire({
      title: "Aguarde!",
      text: "O relatório está sendo gerado!",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await api
        .get(`/cancelations/xlsx?cpf_doador=${cpf}&data_doacao=${dataDoacao}`)
        .then((response) => {
          if (response.data.error) {
            Swal.fire("Atenção", response.data.error, "warning");
          } else {
            window.open(`${baseURL}/cancelamentos?auth=${auth}`);
            Swal.close();
          }
        });
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Background>
      <Content>
        <h1 style={{ fontWeight: "bolder" }}> Cancelamentos </h1>
        <hr />
        <Form onSubmit={handleCancelationsDownload}>
          <Button>
            <button type="submit">Baixar Planilha</button>
          </Button>
        </Form>
        <FiltroContainer>
          <label htmlFor="select-cpf">CPF</label>
          <select
            autoFocus
            id="select-cpf"
            name="select-cpf"
            onChange={(e) => setCPF(e.target.value)}
          >
            <option value="">Selecione um CPF</option>
            {cpfs.map((c, index) => (
              <option key={index} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>
          <label htmlFor="select-data">Data</label>
          <select
            autoFocus
            id="select-data"
            name="select-data"
            onChange={(e) => setDataDoacao(e.target.value)}
          >
            <option value="">Selecione uma Data</option>
            {datasDoacoes.map((d, index) => (
              <option key={index} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>
        </FiltroContainer>
        <TableCancelamentos data={data.slice((page - 1) * 10, page * 10)} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
    </Background>
  );
};

export default Cancelamentos;
