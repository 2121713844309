import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
} from "./styles";
import api from "../../../services/api";
import Upload from "./UploadImage";
import swal from "sweetalert";
import iconxlsx from "../../../Assets/Images/xls.png";

export default function ModalPlanilha({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [foto, setFoto] = useState([]);
  const [haveImage, setHaveImage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [url_foto, setUrl_foto] = useState(null);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    foto.map((file) => setUrl_foto(URL.createObjectURL(file)));
    if (foto.length) {
      setHaveImage(true);
    } else {
      setHaveImage(false);
    }
  }, [foto]);

  useEffect(() => {}, []);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    let formData = new FormData();
    if (foto.length) foto.forEach((file) => formData.append(`file`, file));

    try {
      const response = await api.patch(`/admin/usersxlsx`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
        },
      });

      if (response.data.error) {
        return swal("Atenção!", response.data.error, "warning");
      }
      swal("Sucesso!", response.data.success, "success");
      setTimeout(() => {
        setShowModal(!showModal);
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) =>
            modalRef.current === e.target ? setShowModal(!showModal) : null
          }
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Inserir doadores</h3>
                <FormContent>
                  {haveImage ? (
                    <img
                      style={{
                        width: "10%",
                        heigth: "10%",
                        alignSelf: "center",
                      }}
                      alt="erro"
                      src={iconxlsx}
                    />
                  ) : (
                    <Upload
                      amount={1}
                      msg={`( Planilha ) `}
                      setFile={setFoto}
                    />
                  )}
                </FormContent>
                <br />
                <Button type="submit">Ler Planilha</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
