import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);

  overflow-y: auto;
  position: fixed;
  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
  overflow: auto;
`;

export const Covid = styled.div`
  margin-top: 10vh;
  margin-left: 3vh;
  position: relative;
  img {
    width: 40px;
    height: 50px;
  }

  button {
    width: 200px;
    height: 50px;
    border-radius: 20px;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }
`;

export const Logos = styled.div`
  display: flex;
  margin-top: 86px;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 420px) {
    flex-direction: column;
    justify-content: none;
    align-items: center;
    margin-top: 56px;
  }
`;
// export const Indaituba = styled.div`
// img{
//    @media(max-width:920px){
//        width:292px;
//        height:115px;
//     }

//     @media(max-width:660px){
//        width:192px;
//        height:85px;
//     }

//   }
// `

export const Indaituba = styled.div`
  img {
    width: 360px;
    height: 80px;
    margin-top: 30px;
    object-fit: contain;
    @media (max-width: 1199px) {
      margin-top: 15px;
      width: 300px;
      height: 75px;
    }
    @media (max-width: 991px) {
      width: 200px;
      height: 75px;
    }

    @media (max-width: 767px) {
      width: 180px;
      height: 50px;
      margin-top: 0px;
    }
    @media (max-width: 503px) {
      width: 170px;
      height: 90px;
    }
  }
`;

export const Fiec = styled.div`
  img {
    width: 311px;
    height: 173px;
    object-fit: contain;
    @media (max-width: 1199px) {
      width: 200px;
      height: 115px;
    }
    @media (max-width: 991px) {
      width: 180px;
      height: 105px;
    }

    @media (max-width: 767px) {
      width: 142px;
      height: 85px;
      margin-top: -15px;
    }
    @media (max-width: 503px) {
      width: 122px;
      height: 100px;
      margin-top: -5px;
    }
    @media (max-width: 420px) {
      margin-top: -15px;
    }
  }
`;

// LISTA Média
export const Lista = styled.div`
  color: black;
  font-weight: bolder;
  line-height: 40px;

  ul {
    height: 470px;
    overflow-y: scroll;
  }
`;

// LISTA GRANDE
export const Lista2 = styled.div`
  color: black;
  font-weight: bolder;
  line-height: 40px;

  ul {
    height: 320px;
    overflow-y: scroll;
  }
`;

// LISTA PEQUENA SEM OVERFLOW
export const Lista3 = styled.div`
  color: black;
  font-weight: bolder;
  line-height: 40px;
`;

export const ListaP = styled.div`
  color: black;
  font-weight: bolder;
  line-height: 35px;

  ul {
    height: 470px;
    overflow-y: scroll;
  }
  ol {
    height: 470px;
    overflow-y: scroll;
  }
`;

export const Texto = styled.div`
  color: black;
  font-weight: bolder;
`;

export const Button = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 60%;

  button {
    width: 200px;
    height: 20px;
    border-radius: 5px;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }
`;

export const Tittle = styled.div`
  display: flex;
`;
