import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { AiOutlineSend } from "react-icons/ai";

export const Container = styled.div`
  display: none;
  position: absolute;
  bottom: 50px;
  right: 50px;
  max-width: 350px;
  max-height: 550px;
  width: ${(props) => (props.open ? "100%" : "56px")};
  height: ${(props) => (props.open ? "100%" : "56px")};
  background-color: gray;
  border-radius: ${(props) => (props.open ? "10px" : "50%")};
  cursor: ${(props) => (props.open ? "default" : "pointer")};
  flex-direction: column;
  transition: ${(props) =>
    props.open
      ? "height 0.5s, width 0.5s, border-radius 0.1s"
      : "height 0.5s, width 0.5s, border-radius 0.2s ease 0.3s"};

  @media (max-width: 450px) {
    bottom: 10px;
    right: 10px;
  }

  @media (max-width: 350px) {
    bottom: 5px;
    right: 5px;
    width: ${(props) => (props.open ? "95%" : "56px")};
  }
`;

export const Header = styled.div`
  overflow: hidden;
  height: 50px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 0.4s;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const Title = styled.div``;

export const Close = styled(MdClose)`
  cursor: pointer;
`;

export const Chat = styled.div`
  height: 100%;
  width: 100%;
  background-color: lightblue;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 0.4s;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  gap: 10px;
  overflow: auto;
`;

export const BotMessage = styled.div`
  background-color: red;
  align-self: flex-start;
  max-width: 85%;
  width: fit-content;
  padding: 12px;
  border-radius: 0 18px 18px 18px;
`;

export const UserMessage = styled.div`
  background-color: blue;
  align-self: flex-end;
  max-width: 85%;
  width: fit-content;
  padding: 12px;
  border-radius: 18px 18px 0 18px;
`;

export const Buttons = styled.div`
  max-width: 85%;
  display: flex;
  /* border: 1px solid purple; */
  flex-wrap: wrap;
  gap: 10px;
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: red;
  align-self: flex-start;
  max-width: 85%;
  width: fit-content;
  padding: 12px;
  border-radius: 18px 18px 18px 18px;
`;

export const InputContainer = styled.form`
  height: 50px;
  background-color: lightgreen;
  border-radius: 0 0 10px 10px;
  align-items: center;
  display: flex;
  transform-origin: 60%;
  transition: all 0.4s;
  opacity: ${(props) => (props.open ? 1 : 0)};
`;

export const Input = styled.input`
  background-color: lightgreen;
  height: 100%;
  width: 100%;
  border: none;
  margin: 0;
  text-align: left;
  padding: 0 5px;
  border-radius: 0 0 0 10px;

  ::placeholder {
    color: ${(props) => (props.valid ? "black" : "red")};
  }
`;

export const SendButton = styled(AiOutlineSend)`
  cursor: pointer;
  width: 50px;
`;
