import React from 'react';
import { Container } from './styles';

export default function Pagination({ page, setPage, totalPages }){
    return(
        <Container>
            {page <= 1 ? (
                <button disabled>Anterior</button>
            ) : (
                <button type='button' onClick={() => setPage(page - 1)}>Anterior</button>
            )}
            <small>Página {page} / {totalPages}</small>
            {page < totalPages ? (
                <button type='button' onClick={() => setPage(page + 1)}>Próximo</button>
            ) : (
                <button disabled>Próximo</button>
            )}
        </Container>
    );
}