import React, { useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";

import "./radioButton.css";
import { Radio, Form, Input, Button, DivButton } from "./style";

export default function RadioButton({
  doador,
  idCalendario,
  horariosCalendario,
  fetchDataAgendamento,
}) {
  const [horario, setHorario] = useState();

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id_calendario: idCalendario,
      horario_doacao: horario,
      cpf_doador: doador?.cpf,
    };

    if (!horario)
      return Swal.fire({
        title: "Atenção!",
        text: "Escolha um horário!",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });

    try {
      const response = await api.post(`/registrations`, { data });
      localStorage.setItem("horario", horario);
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire({
        title: "Sucesso!",
        html: `
          <p>Agradecemos sua participação ${doador?.nome}.</p>
          <br />
          <p>Seu agendamento não é apenas um gesto - é uma promessa de vida. Sua doação pode salvar até quatro pessoas. Sua responsabilidade hoje é um presente de esperança amanhã!</p>
          <strong>Em breve as informações do agendamento serão enviadas em seu email (Verifique seu SPAM).</strong>
          <p>FIEC I - Av. Eng. Fábio Roberto Barnabé, 3405 - Jardim Regina, Indaiatuba</p>
        `,
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "var(--red-color)",
      }).then(() => {
        fetchDataAgendamento(doador?.cpf);
      });
    } catch (error) {
      Swal.fire("Atenção!", "Escolha um horário", "warning");
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {horariosCalendario.map((h, i) =>
          h.vagas <= h.vagas_preenchidas ? (
            <Radio key={i}>
              <Input
                disabled
                type="radio"
                className="option-input radio"
                style={{ cursor: "not-allowed" }}
                value={h.horario_doacao}
                checked={horario === h.horario_doacao}
                onChange={(e) => setHorario(e.target.value)}
              />
              <span style={{ color: "red" }}>
                {h.horario_doacao + " - Esgotado"}
              </span>
            </Radio>
          ) : (
            <Radio key={i} onClick={() => setHorario(h.horario_doacao)}>
              <Input
                type="radio"
                className="option-input radio"
                value={h.horario_doacao}
                checked={horario === h.horario_doacao}
                onChange={(e) => setHorario(e.target.value)}
                required
              />
              <span>{h.horario_doacao}</span>
            </Radio>
          )
        )}
        <DivButton>
          <Button type="submit">Confirmar</Button>
        </DivButton>
      </Form>
    </>
  );
}
