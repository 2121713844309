import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: fixed;
  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
`;

export const Titulo = styled.h1`
  font-weight: 700;
`;

export const ContentForm = styled.div`
  width: 40%;
  height: 35%;
  min-height: 345.3px;
  background: #fff;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 1311px) {
    width: 55%;
  }
  @media (max-width: 930px) {
    width: 75%;
  }
  @media (max-width: 688px) {
    width: 85%;
  }
  @media (max-width: 399px) {
    min-height: 375.3px;
    top: 17%;
  }
`;

export const Form = styled.form``;

export const FormController = styled.div`
  margin-top: 0.8rem;

  @media (max-width: 399px) {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

export const Input = styled.input`
  float: right;
  background-color: #f3f3f3;
  border: none;
  border-radius: 20px;
  height: 30px;
  width: 220px !important;
  text-align: center;
`;

export const Label = styled.label`
  padding-right: 12px;
  margin-left: -40px;

  @media (max-width: 399px) {
    margin-left: 9px;
  }
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 0.8rem;

  a:hover {
    color: red;
    font-size: 120%;
  }

  &:hover {
    color: red;
    text-decoration: underline;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    font-weight: bolder;
    margin-top: 20px;
    background: var(--red-color);
    border: 0;
    border-radius: 0.25rem;
    padding: 0.6rem 0.5rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Forgot = styled.div`
  position: absolute;
  top: 126px;
  right: 25px;

  @media (max-width: 619px) {
    top: 34px;
  }
  @media (max-width: 399px) {
    top: 239px;
    right: 0;
  }

  &:hover {
    opacity: 90%;
    transform: scale(1.1);
  }

  img {
    width: 30px;
    height: 34px;
    object-fit: cover;

    @media (max-width: 619px) {
      width: 27px;
      height: 31px;
    }
  }

  legend {
    font-size: 11px;
    text-align: center;
    @media (max-width: 619px) {
      font-size: 7px;
    }
  }

  button {
    background: white;
  }
`;

export const Container = styled.div`
  width: 100vw;
  /* height: 70vh; */
  height: 55vh;
  display: grid;
  justify-items: center;
  align-items: center;
`;

export const AddAgenda = styled(Link)`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  /* padding: .625rem 1.25rem; */
  line-height: 1.5;
  border-radius: 10px;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  color: #fff;
  border: none;
  background: var(--red-color);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transition: all 0.3s linear;
  text-decoration: none;
  padding: 10px;
  &:hover {
    color: #fff;
    filter: brightness(90%);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
`;
