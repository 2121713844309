import React from "react";
import { TableContainer, TableBody, TableHead } from "./styles";

export default function TableCancelamentos({ data }) {
  return (
    <TableContainer>
      <TableHead>
        <tr>
          <td>CPF do Doador</td>
          <td>Nome do Doador</td>
          <td>Data/Hora de Operação</td>
        </tr>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <tr key={i}>
            <td data-title="Nome do Doador">{d?.cpf}</td>
            <td data-title="Nome do Doador">{d?.nome}</td>
            <td data-title="Data/Hora de Operação">
              {d.data_envio
                ? new Date(d.data_envio).toLocaleString()
                : "Email não enviado"}
            </td>
          </tr>
        ))}
      </TableBody>
    </TableContainer>
  );
}
