import React, { useState, useEffect } from "react";
import { Background, Content, Button, FiltroContainer } from "./style";

import api, { baseURL } from "../../services/api";
import Auth from "../../services/auth";

import Swal from "sweetalert2";
import { format } from "date-fns";

import TableGrupoDoadores from "../../Components/Tables/TableGruposDoadores";

const TodosGruposDoadores = () => {
  const [auth] = useState(Auth.auth());
  const [data, setData] = useState([]);

  const [cpf] = useState("");
  const [cpfs, setCPFs] = useState([]);

  const [dataDoacao, setDataDoacao] = useState("Nenhum");
  const [datasDoacoes, setDatasDoacoes] = useState([]);

  async function fetchData(cpf, dataDoacao) {
    let query = "";
    if (cpf || (dataDoacao && dataDoacao !== "Nenhum")) {
      query = "?";
      if (cpf) {
        query += `cpf_doador=${cpf}&`;
      }
      if (dataDoacao) {
        query += `data_doacao=${dataDoacao}`;
      }
    }

    try {
      const response = await api.get(`/schedule_a_donor_group${query}`);
      const responseData = response.data;

      if (dataDoacao !== "Nenhum") {
        setData(responseData);
      } else {
        setData([]);
      }

      const datasUnicas = new Set();
      const cpfsUnicos = new Set();

      if (datasDoacoes.length === 0 || cpfs.length === 0) {
        const formatandoDatas = [];
        const formatandoCPFs = [];

        responseData.forEach((item) => {
          if (!cpfsUnicos.has(item.cpf_usuario)) {
            cpfsUnicos.add(item.cpf_usuario);
            formatandoCPFs.push({
              label: item.cpf_usuario,
              value: item.cpf_usuario,
            });
          }
          item?.data_doacoes.forEach((doacao) => {
            if (!datasUnicas.has(doacao.data_doacao)) {
              datasUnicas.add(doacao.data_doacao);
              formatandoDatas.push({
                label: format(new Date(doacao.data_doacao), "dd/MM/yyyy"),
                value: doacao.data_doacao.split("T")[0],
              });
            }
          });
        });

        if (cpfs.length === 0) {
          setCPFs(formatandoCPFs);
        }

        if (datasDoacoes.length === 0) {
          setDatasDoacoes(formatandoDatas);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData(cpf, dataDoacao);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf, dataDoacao]);

  async function handleGrupoDoadoresDownload() {
    Swal.fire({
      title: "Aguarde!",
      text: "O relatório está sendo gerado!",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let query = "";
    if (cpf || (dataDoacao && dataDoacao !== "Nenhum")) {
      query = "?";
      if (cpf) {
        query += `cpf_doador=${cpf}&`;
      }
      if (dataDoacao) {
        query += `data_doacao=${dataDoacao}`;
      }
    }

    try {
      await api
        .post(`/schedule_a_donor_group/xlsx${query}`)
        .then((response) => {
          if (response.data.error) {
            Swal.fire("Atenção", response.data.error, "warning");
          } else {
            window.open(`${baseURL}/grupo_doadores?auth=${auth}`);
            Swal.close();
          }
        });
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Background>
      <Content>
        <div>
          <h1 style={{ fontWeight: "bolder" }}> Grupos de Doadores </h1>
          <Button onClick={() => handleGrupoDoadoresDownload()}>
            Baixar Planilha
          </Button>
        </div>
        <FiltroContainer>
          <label htmlFor="select-data">Data da Doação</label>
          <select
            autoFocus
            id="select-data"
            name="select-data"
            onChange={(e) => setDataDoacao(e.target.value)}
          >
            {/* Mexendo */}
            <option value="Nenhum">Selecione uma Data</option>
            <option value="">Todos as Datas</option>
            {datasDoacoes.map((d, index) => (
              <option key={index} value={d.value}>
                {d.label}
              </option>
            ))}
          </select>
        </FiltroContainer>
        <TableGrupoDoadores data={data} />
      </Content>
    </Background>
  );
};

export default TodosGruposDoadores;
