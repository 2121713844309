import styled from 'styled-components'
import {Link as Rota} from "react-router-dom";


export const Logos = styled.div `
display: flex;
margin-top: 10vh;
justify-content: space-around;
width: 100%;

@media(max-width:420px){
   flex-direction: column;
   justify-content:none;
   align-items: center;
}
`
export const Fiec = styled.div`
 img { 
    width: 311px;
    height: 173px;

    @media(max-width:920px){
       width:200px;
       height:115px;
    }

    @media(max-width:660px){
       width:142px;
       height:85px;
    }
    
 }

`

export const Indaituba = styled.div`
  img{
   @media(max-width:920px){
       width:292px;
       height:115px;
    }

    @media(max-width:660px){
       width:192px;
       height:85px;
    }
    
  }
`

export const Texto = styled.div`
    width: 100%;
    text-align: center;

    p{
    color: black;
    font-weight: bolder;
    font-size: 30px;
    letter-spacing: 2px; word-spacing: 3px;

    @media(max-width:670px){
       font-size:26px;
    }
    }
`




export const Finalizar = styled.div`
margin-top: 15vh;
display: flex;
justify-content: center;
width: 100%;
`

export const Link = styled(Rota)`
text-decoration:underline;
color:red;
text-align:center;
font-size: 30px;
font-weight: bolder;

   &:hover{
      color: red;
      font-size:200%;
   }
`