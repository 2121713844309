import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  padding: 1.25rem;
  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 70%;
  width: 100%;
  min-height: 254px;
  max-height: 70vh;
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    max-width: 88%;
    top: 60px;
  }

  @media (max-width: 800px) {
    max-width: 96%;
    padding: 1rem;
    font-size: 0.8rem;
  }

  h1 {
    margin-bottom: 1.25rem;
    text-align: center;
  }

  table {
    width: fit-content;
    margin: 0 auto;

    border-collapse: collapse;
    overflow-x: auto;
    max-height: 50vh;
    overflow-y: auto;
    display: block;

    thead {
      position: sticky;
      border: 1px solid black;
      top: 0;
      z-index: 1;
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
      max-height: 400px;
      overflow-y: auto;
    }

    th,
    td {
      border: 1px solid black;
      padding: 8px;
      text-align: center;

      @media (max-width: 800px) {
        padding: 4px;
      }
    }

    th {
      background-color: #f2f2f2;
      word-wrap: break-word;
      width: 400px;
    }

    td:nth-child(5),
    td:nth-child(6) {
      width: 50px;

      img {
        width: 2.5rem;
        height: auto;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          background-color: #d4d4d4;
        }

        @media (max-width: 800px) {
          width: 2rem;
        }
      }
    }
  }
`;
