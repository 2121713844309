import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Cont = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  display: block;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  box-shadow: 1px 8px 10px 7px rgba(0, 0, 0, 0.2);

  img {
    width: 560px;
    height: 800px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 660px) {
    width: 100%;
    img {
      width: 360px;
      height: 430px;
    }
  }
`;

export const Indaituba = styled.div`
  img {
    width: 360px;
    height: 80px;
    margin-top: 30px;
    object-fit: contain;
    @media (max-width: 920px) {
      width: 315px;
      height: 80px;
    }

    @media (max-width: 660px) {
      margin-top: 35px;
      width: 210px;
      height: 50px;
    }
  }
`;

export const Link = styled(Rota)`
  padding: 5px;
  border-radius: 15px;
  background-color: red;
  color: white;
  text-align: center;
  width: 50%;
  font-size: 30px;
  font-weight: bolder;
  transition: 0.2s linear;
  text-decoration: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  a:link,
  a:visited,
  a:active {
    text-decoration: none;
  }
  a:hover {
    color: white;
    font-size: 107%;
    transition: 0.2s linear;
  }

  &:hover {
    color: red;
    background-color: white;
  }

  @media (max-width: 660px) {
    height: 50px;
    width: 100%;
    font-size: 20px;
  }
`;
