import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
require("dotenv").config({ path: __dirname + "../.env" });

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");

  try {
    jwt.verify(token, "doacaosangue@citec");
    return true;
  } catch (error) {
    localStorage.clear();
    return false;
  }
};

export const isAuthenticatedAdmin = () => {
  const token = localStorage.getItem("token");

  try {
    jwt.verify(token, "doacaosangue@citec");
    var decoded = jwt_decode(token);
    if (decoded.admin) return true;
    else return false;
  } catch (error) {
    localStorage.clear();
    return false;
  }
};

export const userDecoded = () => {
  const token = localStorage.getItem("token");

  try {
    jwt.verify(token, "doacaosangue@citec");
    var decoded = jwt_decode(token);
    if (decoded) return;
    return decoded;
  } catch (error) {
    localStorage.clear();
    return false;
  }
};
