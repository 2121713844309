import styled from 'styled-components';

export const Box = styled.div`
  padding: 18px 60px;
  background: var(--red-color);
  position: absolute;
  bottom: 0;
  width: 100%;
  color: whitesmoke;

  @media (max-width: 1000px) {
    padding: 18px 30px;
  }

  /* @media (max-width: 452px) {
    text-align: center;
  } */

  @media (max-height: 740px) {
    position: static;
    bottom: 0;
  }
`;

export const Container = styled.div`
    display: grid;
    height: 115px;
    justify-items: center;
    width: 100%;
    max-width: 1090px;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 't l'
                         'c l';

    a {
      text-decoration:none;
      color: white;
    }

    @media (max-width: 500px) {
      /* grid-template-areas: 't'
                           'l'
                           'c'; */
      display: flex;
      flex-direction: column;
      height: fit-content;
    }
    `;

export const Copyright = styled.div`
  width: 100%;
  grid-area: c;

  @media (max-width: 500px) {
      text-align: center;
  }
`;

export const Text = styled.div`
  width: 100%;
  grid-area: t;

  @media (max-width: 500px) {
      text-align: center;
  }
`;

export const Links = styled.div`
  width: 100%;
  display: flex;
  justify-content:flex-end;
  grid-area: l;

  @media (max-width: 500px) {
      justify-content: left;
      padding-bottom: 10px;
  }
`;