import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.25rem;
  overflow-x: scroll;
  background: #fff;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1350px) {
    /* display: block; */
    /* width: 90%; */
  }
`;

export const TableHead = styled.thead`
  height: 55px;
  color: #e6e7e9;

  text-align: center;
  text-transform: uppercase;

  background: var(--red-color);

  th {
    font-weight: bolder;
    color: #fff;
    padding: 1rem 0;
  }

  th,
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 1350px) {
    display: none;
  }
`;

export const TableBody = styled.tbody`
  font-size: 12px;
  font-weight: bolder;
  tr {
    text-align: center;
    border-bottom: 1px solid #f4f4f4;
    cursor: pointer;
    will-change: transform;
    transition: all 0.3s linear;
    @media screen and (max-width: 1350px) {
      border-bottom: 1px solid #d1d1d1;
    }
    td {
      padding: 0.9rem 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 1350px) {
        font-weight: lighter;
        display: block;
        width: 100%;
        text-align: right;
        padding-right: 3%;
        text-align: right;
        position: relative;
        &:before {
          content: attr(data-title);
          position: absolute;
          left: 0;
          width: 6%;
          padding-left: 15px;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    &:hover {
      filter: brightness(90%);
      box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
      transform: translateY(-1px);
      background: #e6e7e9;
    }
  }
`;

export const Content = styled.div`
  width: 95%;

  overflow: scroll;
  height: 200px;
  min-height: 254px;
  max-height: 260px;
  background: #fff;
  padding: 2rem 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 115px;
  left: 50%;
  transform: translate(-50%);
  max-height: 870px;

  @media (max-width: 464px) {
    width: 87%;
  }
`;
