import { createGlobalStyle, css } from "styled-components";
import styled from "styled-components";
export default createGlobalStyle`
  :root {
    --red-color: #c22a40;
    --small-font-size: .875rem;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
    outline:0;
    -webkit-overflow-scrolling: touch; /* deixa a rolagem suave */
  }
  body {
    width: calc(100vw);
    height: 100vh;
    margin-right: 50px;
  }
`;

export const Button = styled.div`
  button {
    background: #00d4ff;
    border: 0;
    border-radius: 0.25rem;
    padding: 0.6rem 0.5rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }
`;

export const Tooltip = css`
  &::before,
  &::after {
    position: absolute;
    top: -0.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0))
      scale(var(--tooltip-scale));
    transition: 150ms transform;
    transform-origin: bottom center;
  }
  &::before {
    --translate-y: calc(-100% - var(--tooltip-arrow-size));
    content: attr(data-tooltip);
    font-size: 10px;
    color: #fff;
    padding: 5px;
    border-radius: var(--border-radius);
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
  }
  &::after {
    --translate-y: calc(-1 * var(--tooltip-arrow-size));
    content: "";
    border: var(--tooltip-arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
  }
  &:hover::before,
  &:hover::after {
    --tooltip-scale: 1;
  }
`;
