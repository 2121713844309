import styled from "styled-components";

export const Radio = styled.div`
  width: 250px;
  padding: 10px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: #d4d4d4;

  &:hover {
    transform: scale(1.1);
    border-width: 3px;
  }
`;

export const Form = styled.form`
  width: 100% !important ;
  padding-bottom: 50px !important;

  display: flex !important;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 0.3125rem;
`;

export const Input = styled.input`
  margin: auto 0;
  text-align: left !important;
`;

export const DivButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  margin-top: 10px;

  background: var(--red-color);
  border: 0;
  border-radius: 0.25rem;
  padding: 8px;
  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    transform: scale(1.1);
  }
`;
