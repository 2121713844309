import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);

  overflow-x: auto;
  position: fixed;

  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
`;

export const Content = styled.div`
  max-height: 800px;
  min-height: 255px;
  height: fit-content;

  width: 70%;

  display: flex;
  flex-direction: column;

  gap: 0.875rem;
  margin: auto 0;
  padding: 2rem 2rem;

  background: #fff;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;

  overflow-y: auto;

  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 1024px) {
    width: 88%;
    top: 60px;
  }

  @media (max-width: 800px) {
    width: 96%;
    padding: 2rem 0.8rem;
    font-size: 0.8rem;
  }

  .select-calendar {
    max-width: fit-content;
    width: 100%;
    height: 1.875rem;
    text-align: center;
    font-size: 1.25rem;
    margin: 0 0 0 auto;

    &:focus {
      border-width: 2px;
    }
  }

  .btn-add {
    height: 2.5rem;
    width: 2.5rem;

    margin-left: 0.625rem;

    background-color: #60a5fa;

    > img {
      width: 100%;
    }

    &:hover {
      transition: 0.3s;
      background-color: #2563eb;
    }

    &:disabled {
      cursor: no-drop;
    }
  }

  > button {
    margin: 0;
    padding: 0;

    min-width: 320px;
    width: 100%;
    min-height: 2.5rem;
    height: fit-content;

    font-size: 1.25rem;

    border-width: 1px;
    border-style: solid;
    border-color: #18181b;
    border-radius: 0.5rem;

    &:hover {
      transition: 0.3s;
      background-color: #d4d4d8;
    }
  }

  h1 {
    margin-bottom: 1.25rem;
  }

  table {
    min-width: 320px;
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    height: fit-content;
  }

  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;

    border: 1px solid black;
    padding: 8px;
    text-align: center;
    @media (max-width: 800px) {
      padding: 8px 3px;
    }
  }

  th {
    background-color: #f2f2f2;
    word-wrap: break-word;
  }

  > div {
    min-width: 320px;
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;

    > label {
      text-align: start;
      font-size: 2rem;
    }

    > select,
    button {
      margin: 0;
      padding: 0;

      width: 50%;
      height: 30px;
      border-width: 1px;
      border-style: solid;
      border-color: #18181b;
      border-radius: 0.5rem;
    }

    h1 {
      margin: auto 0;

      text-align: start;
      font-size: 2rem;
    }
  }

  strong {
    width: 100%;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-width: 1px;
    border-style: solid;
    border-color: #18181b;
    border-radius: 0.5rem;
  }

  table:nth-child(2) {
    th,
    td {
      width: 20%;
      height: auto;
    }
  }

  table:nth-child(4) {
    th,
    td {
      width: 30%;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: fit-content;
      img {
        width: 25px;
        border-radius: 8px;
      }
    }

    td:nth-child(5) {
      cursor: pointer;
      &:hover {
        background-color: #d4d4d4;
      }
      img {
        width: 25px;
        border-radius: 8px;
      }
    }
  }
`;

export const TitleComponent = styled.h1`
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 4px;
  padding-right: 20px;
  text-align: left;
`;

export const ResponseFail = styled.h2`
  color: red;
  font-weight: bold;
`;

export const GrupoDoadoresContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  > button {
    width: 100%;
    min-height: 2.5rem;
    height: fit-content;

    border-width: 1px;
    border-style: solid;
    border-color: #18181b;
    border-radius: 0.3125rem;

    &:hover {
      transition: 0.3s;
      background-color: #d4d4d8;
    }
  }

  > div {
    width: 100%;

    display: flex;
    flex-direction: column;

    padding: 0.625rem;
    border-width: 1px;
    border-style: solid;
    border-color: #18181b;
    border-radius: 0.5rem;

    gap: 0.625rem;

    label {
      text-align: start;
      font-size: 1.125rem;
    }

    input {
      margin: 0;
      padding: 0;

      width: 50%;
      border-width: 1px;
      border-style: solid;
      border-color: #18181b;
      border-radius: 0.5rem;
    }

    select {
      margin: 0;
      padding: 0;

      width: 50%;
      border-width: 1px;
      border-style: solid;
      border-color: #18181b;
      border-radius: 0.5rem;
    }

    button {
      width: 100%;
      min-height: 2.5rem;
      height: fit-content;

      border-width: 1px;
      border-style: solid;
      border-color: #18181b;
      border-radius: 0.5rem;

      background-color: #dc2626;

      &:hover {
        transition: 0.3s;
        background-color: #991b1b;
      }
    }

    > div {
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const ResponsePreAgendamentoConteiner = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 0.625rem;

  h2 {
    font-size: 1.8rem;
    background-color: #e4e4e7;
  }
`;

export const ResponsePreAgendamentoSubConteiner = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;

  > div {
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid black;
  }

  strong {
    text-align: start;

    @media (max-width: 400px) {
      font-size: 0.9rem;
    }
  }

  span {
    text-align: end;

    @media (max-width: 400px) {
      font-size: 0.9rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    background-color: #e4e4e7;
  }
`;

export const ResponseAgendamentoConteiner = styled.div`
  min-width: 620px;
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;

  overflow-x: auto;

  table,
  th,
  td {
    text-align: center;

    border: 1px solid black;
    border-collapse: separate;
  }

  th {
    padding: 0.3125rem;
  }

  td {
    padding: 0.3125rem 0.625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ResponseAgendamentoSubConteiner = styled.tr`
  width: 100%;
`;
