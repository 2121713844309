import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import {
  Container,
  Header,
  Title,
  Close,
  Chat,
  InputContainer,
  Input,
  SendButton,
  BotMessage,
  UserMessage,
  Buttons,
  Button,
} from "./style";

export default function ChatBot() {
  const [open, setOpen] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [valid, setValid] = useState(true);
  const [trigger, setTrigger] = useState(false);
  const animation = useSpring({
    config: {
      duration: 100,
    },
    to: [
      {
        transform: valid
          ? `rotate(0)`
          : trigger
          ? `rotate(1deg)`
          : `rotate(1deg)`,
      },
      {
        transform: valid
          ? `rotate(0)`
          : trigger
          ? `rotate(-1deg)`
          : `rotate(-1deg)`,
      },
      {
        transform: valid
          ? `rotate(0)`
          : trigger
          ? `rotate(1deg)`
          : `rotate(1deg)`,
      },
      {
        transform: valid
          ? `rotate(0)`
          : trigger
          ? `rotate(-1deg)`
          : `rotate(-1deg)`,
      },
      {
        transform: valid
          ? `rotate(0)`
          : trigger
          ? `rotate(1deg)`
          : `rotate(1deg)`,
      },
      {
        transform: valid
          ? `rotate(0)`
          : trigger
          ? `rotate(-1deg)`
          : `rotate(-1deg)`,
      },
      {
        transform: valid ? `rotate(0)` : trigger ? `rotate(0deg)` : `rotate(0)`,
      },
    ],
  });
  const [data, setData] = useState({
    teste: "testado",
    messages: [
      {
        type: "bot",
        text: "Olá! Como posso te ajudar?",
      },
      {
        type: "buttons",
        text: "Escolha uma opção!",
        buttons: ["Clique aqui!", "Opção 2", "Opção 3"],
      },
      {
        type: "user",
        text: "Senha!",
      },
    ],
  });

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  // useEffect(() => {
  //   console.log(valid);
  // }, [valid]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (inputValue) {
      setData((d) => ({
        ...d,
        messages: [...d.messages, { type: "user", text: inputValue }],
      }));
      setInputValue("");
    } else {
      setValid(false);
      setTrigger(!trigger);
    }
  }

  return (
    <Container open={open} onClick={() => open || setOpen(true)}>
      <Header open={open}>
        <Title>Tire Suas Dúvidas Aqui!</Title>
        <Close onClick={() => setOpen(false)} size={30} />
      </Header>
      <Chat open={open}>
        {data.messages
          .slice()
          .reverse()
          .map((m, i) => (
            <div key={i}>
              {m.type === "bot" ? (
                <BotMessage key={i}>{m.text}</BotMessage>
              ) : m.type === "user" ? (
                <UserMessage key={i}>{m.text}</UserMessage>
              ) : m.type === "buttons" ? (
                <>
                  <Buttons>
                    {m.buttons
                      .slice()
                      .reverse()
                      .map((b, ib) => (
                        <Button key={ib}>{b}</Button>
                      ))}
                  </Buttons>
                  <BotMessage key={i}>{m.text}</BotMessage>
                </>
              ) : null}
            </div>
          ))}
      </Chat>
      <animated.div style={animation}>
        <InputContainer open={open} onSubmit={handleSubmit}>
          <Input
            type="text"
            valid={valid}
            placeholder={
              valid ? "Pergunte algo!" : "Digite uma mensagem válida!"
            }
            value={inputValue}
            onChange={(e) => {
              setValid(true);
              setInputValue(e.target.value);
            }}
            disabled={data.messages[data.messages.length - 1].type === "user"}
          />
          <SendButton type="submit" size={30} onClick={handleSubmit} />
        </InputContainer>
      </animated.div>
    </Container>
  );
}
