import {
  Background,
  Content,
  Tabela,
  Telefone,
  Endereço,
  Site,
  Header,
  Content2,
} from "./style";
import information from "../../Assets/Images/info.png";
import Swal from "sweetalert2";

const Contato = () => {
  return (
    <>
      <Background>
        <Content>
          <h1>Fiec I</h1>
          <hr />
          <Tabela>
            <Telefone>
              <h4>Telefone: (19) 3801-8688</h4>
            </Telefone>
            <Endereço>
              <h4>
                Endereço: FIEC I - Avenida Engenheiro Fábio Roberto Barnabé,
                3405 • Jardim Regina • CEP: 13349-003{" "}
              </h4>
            </Endereço>
            <hr />
          </Tabela>
        </Content>
        <Content2>
          <Header>
            <h1>Hemocentro</h1>
            <button
              onClick={() => {
                Swal.fire({
                  title: "Homocentro",
                  html: '<p align="justify">O Hemocentro orienta que candidatos maiores de 60 anos não podem realizar a doação pela primeira vez. Por se tratar de uma unidade móvel de coleta, as cadeiras portáteis suportam até 110 kg, visando garantir a segurança do doador. Portanto, o doador com peso maior que o limite fica convidado a comparecer a um posto fixo para realizar o atendimento.</p>',
                  confirmButtonColor: "var(--red-color)",
                });
              }}
            >
              <img src={information} alt="Erro" />
            </button>
          </Header>
          <hr />
          <Tabela>
            <Telefone>
              <h4>Telefone: 0800 - 722 8422 </h4>
            </Telefone>
            <Site>
              <h4>Site: www.hemocentro.unicamp.br </h4>
            </Site>
            <hr />
          </Tabela>
        </Content2>
      </Background>
    </>
  );
};

export default Contato;
