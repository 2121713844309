import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Background, Content, Button, Form } from "./style";
import api, { baseURL } from "../../services/api";
import "./inputMask.css";
import Swal from "sweetalert2";
import Auth from "../../services/auth";
import Switch from "../../Components/Switch";
import Pagination from "../../Components/Pagination";
import TableSendingEmails from "../../Components/Tables/TableSendingEmails";

const SendingEmails = () => {
  const history = useHistory();

  const [auth] = useState(Auth.auth());
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(0);

  const [cpfSearch, setCpfSearch] = useState("");
  const [nomeSearch, setNomeSearch] = useState("");

  useEffect(() => {
    if (selected === 0) {
      setCpfSearch(search);
      setNomeSearch("");
    } else {
      setNomeSearch(search);
      setCpfSearch("");
    }
  }, [search, selected]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/donor-reminders?cpf=${cpfSearch}&nome=${nomeSearch}`
        );
        console.log();
        setData(response.data);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          history.push("/");
        }
      }
    };
    fetchData();
    setPage(1);
  }, [nomeSearch, cpfSearch, history]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / 10));
  }, [data]);

  /**
   * Vai servir para gerar a planilha .xlsx do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    Swal.fire({
      title: "Deseja baixar a Planilha?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonColor: "var(--red-color)",
      denyButtonColor: "var(--red-color)",
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Aguarde!",
          text: "O relatório está sendo gerado!",
          icon: "info",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await api.get(`/envio_emails/xlsx`).then((response) => {
            if (response.data.error)
              return Swal.fire("Atenção", response.data.error, "warning");
            window.open(`${baseURL}/envio_emails?auth=${auth}`);
            Swal.close();
          });
        } catch (error) {
          Swal.fire("Erro", "Erro ao gerar relatório", "error");
        }
      }
    });
  }

  return (
    <Background>
      <Content>
        <h1 style={{ fontWeight: "bolder" }}> Envio de Emails </h1>
        <hr />
        <Form onSubmit={handleSubmit}>
          <Button>
            <button type="submit">Baixar Planilha</button>
          </Button>
        </Form>
        <div>
          <Switch
            text1="CPF"
            text2="Nome"
            placeholder1="Insira o CPF"
            placeholder2="Insira o Nome"
            search={search}
            setSearch={setSearch}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <TableSendingEmails data={data.slice((page - 1) * 10, page * 10)} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
    </Background>
  );
};

export default SendingEmails;
