import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);

  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );

  display: flex;
  flex-direction: column;

  > h1 {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%);
  }
`;

export const Container = styled.div`
  width: 40%;

  display: flex;
  flex-direction: column;

  background: #fff;
  padding: 2rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;

  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 740px) {
    width: 85%;
  }

  h1 {
    color: black;
    text-align: center;

    font-weight: 600;
    font-size: 2rem;
  }

  strong {
    width: 100%;
    color: black;
    font-size: 1.5rem;
    text-align: center;
  }

  legend {
    color: #737373;
    text-align: center;

    font-weight: 600;
    font-size: 1.5rem;
  }
`;

export const HorariosContainer = styled.div`
  max-width: 360px;
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  margin: 0 auto;
`;

export const CompromissoContainer = styled.div`
  max-width: 420px;
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  margin: 0 auto;

  label {
    height: 1.25rem;
    width: 100%;
    color: black;
    text-align: start;
  }

  input {
    height: 2.5rem;
    width: 100%;
    text-align: center;

    border-width: 1px;
    border-style: solid;
    border-radius: 0.5rem;

    color: black;
    background-color: #e5e7eb;
  }

  button {
    width: 100%;
    height: fit-content;

    padding: 0.625rem;
    margin-top: 0.625rem;

    text-align: center;

    border-width: 1px;
    border-style: solid;
    border-radius: 0.5rem;

    background-color: #f87171;

    &:hover {
      transition: 0.3s;
      background-color: red;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;

  display: flex;
  justify-content: end;

  @media (max-width: 1499px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: right;

    position: relative;
  }

  p {
    color: gray;
    font-weight: bolder;
    font-size: 20px;
  }

  img {
    width: 30px;
    height: 30px;

    &:hover {
      opacity: 90%;
      transform: scale(1.3);
    }
  }
`;
