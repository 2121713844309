import { CloseButton, ModalBackground, ModalContainer } from "./styles";

function Modal({ children, isOpen, onClose, onSubmit }) {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit();
    onClose();
  };

  return (
    <ModalBackground onClick={handleBackgroundClick}>
      <ModalContainer action="submit" onSubmit={handleSubmit}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalContainer>
    </ModalBackground>
  );
}

export default Modal;
